import { FunctionComponent, PropsWithChildren } from 'react';
import { MarketplaceShellHeader, MarketplaceShellHeaderProps } from './Header';
import { MarketplaceShellFooter } from './Footer';
import { MarketplaceShellContent } from './Content';

export type MarketplaceShellProps = PropsWithChildren<{ header?: MarketplaceShellHeaderProps }>;

export const MarketplaceShell: FunctionComponent<MarketplaceShellProps> = ({
    children,
    header,
}) => {
    return (
        <>
            <MarketplaceShellHeader {...header} />
            <MarketplaceShellContent>{children}</MarketplaceShellContent>
            <MarketplaceShellFooter />
        </>
    );
};

import { FunctionComponent, PropsWithChildren } from 'react';
import BaseLayout, { BaseLayoutProps } from '../BaseLayout';
import { MarketplaceShell, MarketplaceShellProps } from '../../app/components/MarketplaceShell';

export type LayoutProps = PropsWithChildren<BaseLayoutProps & { shell?: MarketplaceShellProps }>;

const Layout: FunctionComponent<LayoutProps> = ({ children, shell, ...baseLayoutProps }) => {
    return (
        <BaseLayout {...baseLayoutProps}>
            <MarketplaceShell {...shell}>{children}</MarketplaceShell>
        </BaseLayout>
    );
};

export default Layout;
